export const loadWhois = {
  loadWhoisRequest: state => {
    state.loading.whois = true;
    state.whois.data = '';
    state.whois.error = ''
  },
  loadWhoisSuccess: (state, {payload}) => {
    state.loading.whois = false;
    state.whois.error = '';
    state.whois.data = payload.data;
  },
  loadWhoisFailure: (state, {payload}) => {
    if (payload.reason) console.log('-----> ', payload.reason);

    state.loading.whois = false;
    state.whois.data = '';
    state.whois.error = payload.message
  },
}
