export const loadDomains = {
  loadDomainsRequest: state => {
    state.domains = [];
    state.domainsSearchParams.categoriesSwitched = 0;
    state.domainsSearchQueryMatch = [];
    state.domainsSearchRelevantZones = [];
    state.domainsSearchSynonyms = [];
    state.loadDomainsValidationMessage = [];
    state.loading.domains = true
    state.loadingDomainsAvailableMinimum = 10
    state.loadingDomainsTimestamp = Date.now()
  },
  loadDomainsSuccess: (state, {payload}) => {
    state.domains = payload.domains.reduce((domains, domain) => {
      if (!domains.find(({ name }) => name === domain.name)) domains.push(domain);
      return domains;
    }, []);
    state.loading.domains = false
  },
  loadDomainsFailure: state => {
    state.domains = [];
    state.loading.domains = false
  },
}
