export const loadCategories = {
  loadCategoriesRequest: state => {
    state.loading.categories = true;
  },
  loadCategoriesSuccess: (state, {payload}) => {
    let categories = payload.filter(({ id, zones }) => (id !== 35 && !!zones));

    state.loading.categories = false;
    state.categories = categories;
    state.selectedCategoryIdZones = categories[0].id
  },
  loadCategoriesFailure: state => {
    state.loading.categories = false;
  },
}
