export const setDomainsSearch = {
  setDomainsSearchQuery: (state, {payload}) => {
    state.domains = [];
    state.domainsSearchParams.query = payload.query;
    state.loadDomainsValidationMessage = [];
    state.loadingDomainsAvailableMinimum = 10
  },
  setDomainsSearchCategory: (state, {payload}) => {
    state.domainsSearchParams.category = payload.id
  },
  setDomainsSearchSynonyms: (state, {payload}) => {
    state.domainsSearchSynonyms = payload.synonyms
  },
  setDomainsSearchRelevantZones: (state, {payload}) => {
    state.domainsSearchRelevantZones = payload.zones
  },
  setDomainsSearchQueryMatch: (state, {payload}) => {
    state.domainsSearchQueryMatch = payload.queryMatch
  },
}
