import {createSlice} from "@reduxjs/toolkit";
import {loadDomainsPriceList} from "./domains/loadDomainsPriceList";
import {loadCategories} from "./domains/loadCategories";
import {loadDomainsValidation} from "./domains/loadDomainsValidation";
import {loadDomains} from "./domains/loadDomains";
import {loadDomainsData} from "./domains/loadDomainsData";
import {loadMoreDomains} from "./domains/loadMoreDomains";
import {loadWhois} from "./domains/loadWhois";
import {loadWhoisDomainData} from "./domains/loadWhoisDomainData";
import {setDomainsSearch} from "./domains/setDomainsSearch";

export const domainsSlice = createSlice({
  name: 'domains',
  initialState: {
    categories: [],
    domains: [],
    domainsPriceList: {},
    domainsSearchQueryMatch: [],
    domainsSearchRelevantZones: [],
    domainsSearchSynonyms: [],
    domainsFilter: {
      showUnavailable: true,
      sorting: ''
    },
    domainsSearchParams: {
      query: '',
      category: -1,
      categoriesSwitched: 0
    },
    loadingDomainsTimestamp: 0,
    loadingDomainsAvailableMinimum: 10,
    loadDomainsValidationMessage: [],
    loading: {
      categories: false,
      domains: false,
      domainsData: false,
      moreDomains: false,
      whois: false
    },
    whois: {
      domain: null,
      data: '',
      error: ''
    },
    popup: {
      whois: false
    },
    selectedCategoryIdZones: -1
  },
  reducers: {
    ...loadDomainsPriceList,
    ...loadCategories,
    ...loadDomainsValidation,
    ...loadDomains,
    ...loadDomainsData,
    ...loadMoreDomains,
    ...loadWhois,
    ...loadWhoisDomainData,

    ...setDomainsSearch,

    setLoadingDomainsAvailableMinimum: (state, {payload}) => {
      state.loadingDomainsAvailableMinimum = payload.minimum
    },
    setShowPopupWhois: (state, {payload}) => {
      state.popup.whois = payload.isOpen;
    },
    setCategoryIdZones: (state, {payload}) => {
      state.selectedCategoryIdZones = payload.id
    },

    toggleDomainsFilterUnavailableVisibility: state => {
      state.domainsFilter.showUnavailable = !state.domainsFilter.showUnavailable
    }

  },
});

export const {
  // loadDomainsPriceListRequest,
  loadDomainsPriceListSuccess,
  loadDomainsPriceListFailure,

  loadCategoriesRequest,
  loadCategoriesSuccess,
  loadCategoriesFailure,

  loadDomainsValidationSuccess,
  loadDomainsValidationError,

  loadDomainsRequest,
  loadDomainsFailure,
  loadDomainsSuccess,

  loadDomainsDataRequest,
  loadDomainsDataSuccess,
  loadDomainsDataFailure,

  loadMoreDomainsRequest,
  loadMoreDomainsSuccess,
  loadMoreDomainsFailure,

  loadWhoisRequest,
  loadWhoisSuccess,
  loadWhoisFailure,

  loadWhoisDomainDataRequest,
  loadWhoisDomainDataSuccess,
  loadWhoisDomainDataFailure,

  setDomainsSearchQuery,
  setDomainsSearchCategory,
  setDomainsSearchSynonyms,
  setDomainsSearchRelevantZones,
  setDomainsSearchQueryMatch,
  setLoadingDomainsAvailableMinimum,
  setShowPopupWhois,
  setCategoryIdZones,

  toggleDomainsFilterUnavailableVisibility
} = domainsSlice.actions

export default domainsSlice.reducer
