import React from 'react';
import {render} from 'react-dom';
import './assets/styles/index.scss';
import {Provider} from "react-redux";
import store from './store'
// import reportWebVitals from './reportWebVitals';

const _importAndRender = async (importComponent, selector) => {
  const els = [...document.querySelectorAll(selector)];
  els.forEach(async el => {
    const pageData = JSON.parse(el.getAttribute('data-props'));
    const { 'default': Component } = await importComponent();
    render(
      <Provider store={store}>
        <Component pageData={pageData} />
      </Provider>,
      el
    );
  });
};

_importAndRender(() => import('./modules/cart'), '#cart');
_importAndRender(() => import('./modules/domain-finder'), '#domain-finder');
_importAndRender(() => import('./modules/whois-finder'), '#whois-finder');
_importAndRender(() => import('./modules/hosting'), '#hosting-tariffs');
_importAndRender(() => import('./modules/vps'), '#vps-tariffs');



// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals(console.log);
