export const loadDomainsPriceList = {
  // loadDomainsPriceListRequest: state => {
  //
  // },
  loadDomainsPriceListSuccess: (state, {payload}) => {
    state.domainsPriceList = Object.keys(payload).reduce((result, key) => {
      const tld = key.slice(1);
      result[tld] = {
        cost: Number(payload[key].cost)
      };
      if (payload[key].old_cost) {
        result[tld].old_cost = Number(payload[key].old_cost);
      }
      return result;
    }, {});
  },
  loadDomainsPriceListFailure: state => {
    state.domainsPriceList = {}
  },
}
