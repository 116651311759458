export const loadWhoisDomainData = {
  loadWhoisDomainDataRequest: state => {
    state.whois.domain = null
  },
  loadWhoisDomainDataSuccess: (state, {payload}) => {
    state.whois.domain = {...payload.data}
  },
  loadWhoisDomainDataFailure: state => {
    state.whois.domain = null
  },
}
