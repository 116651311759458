export const loadMoreDomains = {
  loadMoreDomainsRequest: state => {
    state.domainsSearchParams.categoriesSwitched = ++state.domainsSearchParams.categoriesSwitched;
    state.loading.moreDomains = true;
  },
  loadMoreDomainsSuccess: (state, {payload}) => {
    state.loading.moreDomains = false;
    state.domains = [
      ...state.domains,
      ...payload.domains
        .filter(d => state.domains.every(({ name }) => name !== d.name)) // filtering duplicates with state
        .reduce((domains, domain) => { // filtering inner duplicates
          if (!domains.find(({ name }) => name === domain.name)) domains.push(domain);
          return domains;
        }, [])
    ];
  },
  loadMoreDomainsFailure: state => {
    state.loading.moreDomains = false
  },
}
