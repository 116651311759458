export const loadDomainsData = {
  loadDomainsDataRequest: (state, {payload}) => {
    state.domains = state.domains.map(domain => {
      if (domain.prefetchName && payload.domains.includes(domain.prefetchName)) {
        domain.fetching = true;
      }
      return domain;
    });
    state.loading.domainsData = true
  },
  loadDomainsDataSuccess: (state, {payload}) => {
    state.domains = state.domains
      .map(domain => payload.domains.find(d => d.name === domain.name) || domain)
      .map(domain => {
        const tldName = domain.name.split('.').pop();

        if (domain.prefetch && !domain.fetching) return domain;
        if (domain.fetching) return {
          name: domain.name,
          tld: `.${tldName}`,
          _unableToFetchData: true
        };

        if (domain.available === 'available' && domain.price === 0) domain._unableToFetchData = true;

        if (domain.tld === '.club') domain.premium = true;

        domain.recommend = ['.xn--p1ai', '.pro', '.city', '.top', '.menu', '.pizza', '.cloud', '.site', '.fashion',
          '.photo', '.guru', '.com', '.net', '.info', '.boutique', '.sale', '.center', '.cash', '.pro', '.toys',
          '.life', '.clothing', '.shoes', '.fit', '.bar', '.pub', '.vodka', '.black',
          '.sex', '.blackfriday', '.xxx'].includes(domain.tld);

        if (payload.priceList.hasOwnProperty(tldName)) {
          domain.price = payload.priceList[tldName].cost;
          if (payload.priceList[tldName].old_cost) {
            domain.old_price = payload.priceList[tldName].old_cost;
          }
        } else { // NOT SUPPORTED
          // console.warn('not in price-list', domain);
          return null;
        }

        return domain;
      })
      .filter(Boolean)
      .filter(domain => !domain.premium); // PREMIUM NOT SUPPORTED

    state.loading.domainsData = false
  },
  loadDomainsDataFailure: state => {
    state.loading.domainsData = false
  },
}
